import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import ExitIntentPdf from "../../static/assets/From Chaos to Bliss - Quandary.pdf"

const DownloadWhitePaper = ({ location }) => {
  return (
    <Layout location={location}>
      <Seo
        title="Custom Thank You"
        description="Download your white paper here"
      />
      <div style={{ marginTop: "10vh", padding: "5rem 0" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mx-auto text-center">
              <LazyLoadComponent>
                <a
                  style={{
                    border: "2px solid #000",
                  }}
                  className="p-3 d-inline-block download-pdf-btn btn workato-free-signup mb-0"
                  download
                  href={ExitIntentPdf}
                >
                  Download Your White Paper Here
                </a>
              </LazyLoadComponent>
              <h2 className="mt-4">
                You're now one step closer to stealing back time from your
                business!
              </h2>

              <Link aria-label="link" className="btn btn-primary mt-5" to="/">
                Return Home
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default DownloadWhitePaper
